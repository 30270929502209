<template>
  <div class='framer' v-if='t'>
    <div class='framer-tab'>
      <div :class="[active === 1 ? 'active' : 'common']" @click='changTab(1)'>全部</div>
      <div :class="[active === 2 ? 'active' : 'common']" @click='changTab(2)'>我参与的</div>
    </div>
    <div class='framer-content'>
      <van-swipe ref='myswipe' class="my-swipe" :show-indicators='false' :loop='false' @change='mychange'>
        <van-swipe-item :style="{'min-height': minHeight + 'px'}">
          <div class='framer-wrap' v-show='active === 1'>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              offset='100'
            >
              <framer-common v-for='(item, i) in allTaskData' :bol='i !== total' :token='t' :taskItem='item'  :key='item.id'></framer-common>
            </van-list>
          </div>
        </van-swipe-item>
        <van-swipe-item :style="{'min-height': minHeight + 'px'}">
          <div class='framer-wrap'>
            <van-list
              v-model="loading1"
              :finished="finished1"
              finished-text="没有更多了"
              @load="onLoad1"
              offset='100'
            >
              <framer-common v-for='(item, i) in myTaskData' :bol='i !== total1' :token='t' :taskItem='item'  :key='item.id'></framer-common>
            </van-list>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Framer',
  components: {
    'framer-common': () => import(/* webpackChunkName: "FramerCommon" */ '../../components/FramerCommon')
  },
  data() {
    return {
      active: 1, // 默认全部
      loading: false,
      finished: false,
      searchInfo: {
        fields: -1,
        genre: -1,
        limit: 10,
        page: 1,
        rewardType: -1,
        type: -1
      },
      total: 0,
      allTaskData: [],
      loading1: false,
      finished1: false,
      searchInfo1: {
        fields: -1,
        genre: -1,
        limit: 10,
        page: 1,
        rewardType: -1,
        type: -1
      },
      total1: 0,
      myTaskData: [],
      headersConfig:{},           // 携带token
      t:'', // token传值用
      minHeight: 500
    }
  },
  created(){
   /* const Navigator = navigator.userAgent;
    this.isAndroid = Navigator.match(/(Android);?[\s\/]+([\d.]+)?/) ? true : false;
    this.isIos = !!Navigator.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);*/
    window.updateToken = (token) => {
      this.t = token
      this.headersConfig = {
        headers:{
          'Authorization': token
        }
      }
    }
    if (window.webkit && window.webkit.messageHandlers) {
      // IOS
      window.webkit.messageHandlers.getToken.postMessage('');
    } else {
      window.app.getToken()
    }
    // updateToken('user:token:P20005629:0c428b61ec1e4dd7ab1f39977db73a5b')
  },
  mounted() {
    this.minHeight = window.screen.height - 50
  },
  methods: {
    onLoad() {
      if (this.t) {
      this.getAllTask()
      this.searchInfo.page++
      }
    },
    onLoad1() {
      // console.log(22222)
      this.getmyTask()
      this.searchInfo1.page++
    },
    /* 获得我的任务 */
    async getmyTask() {
      let {data: res} = await this.$api.myTaskList(this.searchInfo1, this.headersConfig)
      if (res.code === 200) {
        if (res.data.records.length === 0) return this.finished1 = true
        this.total1 = res.data.total - 1
        this.myTaskData = this.myTaskData.concat(res.data.records)
        // 加载状态结束
        this.loading1 = false;
      } else {
        this.$toast('该账号已在其他地方登录')
        if (window.webkit && window.webkit.messageHandlers) {
          // IOS
          window.webkit.messageHandlers.goToLogin.postMessage('');
        } else {
          window.app.goToLogin()
        }
      }
    },
    /* 获得全部任务 */
    async getAllTask() {
      let {data: res} = await this.$api.allTaskList(this.searchInfo, this.headersConfig)
      if (res.code === 200) {
        if (res.data.records.length === 0) return this.finished = true
        this.total = res.data.total - 1
        this.allTaskData = this.allTaskData.concat(res.data.records)
        // 加载状态结束
        this.loading = false;
      } else {
        this.$toast(res.msg)
      }
    },
    /* tab按钮最新、最热的切换 */
    changTab(n) {
      if (this.active === n) return // 提升性能
      n === 2 && this.$refs.myswipe.next()
      n === 1 && this.$refs.myswipe.prev()
      this.active = n
    },
    /* 页面滑动切换 */
    mychange(n) {
      if (n) {
        this.active = 2
      } else {
        this.active = 1
      }
    }
  }
}
</script>

<style scoped lang='less'>
.framer {
  width: 100%;
  height: 600px;
  .framer-tab {
   /* position: fixed;
    z-index: 9999;*/
    display: flex;
    justify-content: space-around;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #F0EDED;
    margin-bottom: 15px;
    .active {
      height: 24px;
      font-size: 17px;
      font-weight: 500;
      color: #222222;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #F7321C;
        border-radius: 1px;
      }
    }
    .common {
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      color: #999999;
      /*line-height: 21px;*/
    }
  }
  .framer-content {
    height: calc(100vh - 45px);
    overflow: auto;
    box-sizing: border-box;
    padding: 0 17px;
    .framer-wrap {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style>
.my-swipe .van-swipe-item {
  width: 100px;
}
</style>
