<template>
  <div class="container">
    <div class="author-views" id="box" v-if="authorViews.length">
      <div v-for="(item, index) in authorViews" :key="index" class="views-item">
        恭喜<span class="mark">{{item.author.nickname}}</span>今天的文章达到<span class="mark">{{numberChange(item.views)}}万</span>阅读量。
      </div>
    </div>
    <div class="image-box">
      <img src="https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_1.jpg" alt="">
    </div>
    <div class="content">
      <img class="title_img" src="https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_2.png" alt="">
    </div>
    <div class="content">
      <div class="swiper" v-for="(authorList,index) in swiperData" :key="index">
        <div class="swiper-wrapper" >
            <div class="swiper-slide" v-for="(item) in authorList" :key="item.userId" data-swiper-autoplay="2000">
              <div class="author-swipe-item">
                <div class="author-img-bg">
                  <img class="author-img" :src="item.avatar || 'https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_avatar.png'" alt="">
                </div>
                <p class="nickname">{{item.nickname}}</p>
                <p class="introduction">{{item.introduction}}</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="image-box pos-relative">
      <img :src="isApp ? 'https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_4.png' : 'https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_3.jpg'" alt="">
      <div class="pos-absolute">
        <img v-if="!isApp" class="erweima" src="https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_erweima.jpg" alt="">
        <p v-if="!isApp" class="text">扫码添加微信了解更多 !</p>
        <div class="button" @click="openApp">
          <img :src="isApp ? 'https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_btn2.png' : 'https://cdn.9kd.com/kdnet/invite_guide/kd_invite_guide_btn1.png'" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import Bus from '../../utils/bus';
export default {
  data() {
    return {
      swiperData: [],
      authorViews: [],
      isApp: false
    }
  },
  created() {
    // 全局混入函数
    this.setWeChatShare({
      url: window.location.href,
      title: '创作有价值，分享再增收，凯迪号让你创作更有影响力！',
      description: '一个开放型的内容创作与分享平台，致力于帮助内容创作者在移动端获得更多曝光和关注，同时实现品牌传播、内容变现和商业收益。',
      friendImg: this.$baseInfo.LOGO_IMG,
      momentsImg: this.$baseInfo.MOMENTS_LOGO_IMG
    });
  },
  mounted() {
    let apps = this.getUrlParam('isApp');
    if(apps) {
      this.isApp = true;
    }
    this.getAuthorData();
    this.getList();
  },
  methods:{
    numberChange(value) {
      return Math.ceil(value/10000);
    },
    async getAuthorData() {
      let { data: res } = await this.$api.getRecommendAuthor({
        limit: 300,
        page: 1
      })
      if(res.records && res.records.length) {
        let swiperData = [];
        if(res.records.length > 0 && res.records.length < 6) {
          swiperData.push(res.records)

        } else if (res.records.length >= 6 && res.records.length < 9) {
          let start = res.records.splice(0,3);
          swiperData.push(start,res.records);
        } else {
          let len = Math.floor(res.records.length / 3);
          let start = res.records.splice(0,len);
          let middle = res.records.splice(0,len);
          swiperData.push(start, middle, res.records);
        }
        this.swiperData = swiperData;
      }
      // 轮播
      this.$nextTick(()=>{
        new Swiper('.swiper', {
          slidesPerView: 3,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
        });
      })
    },
    async getList() {
      let { data: res } = await this.$api.hotArticleList({
        type: 3,
        limit: 10, 
        tagId: 1
      })
      if(res.code == 200) {
        this.authorViews = res.data;
      }
      this.$nextTick(()=>{
        if(this.authorViews.length) {
          this.runAnimate();
        }
      })
      
    },
    runAnimate() {
      var index = 0;
      var self = this;
      let width = document.getElementById('app').clientWidth;
      const element = document.getElementById('box');
      let pixed = width;
      function run() {
        pixed -= 1.5;
        element.style.transform = 'translateX(' + pixed + 'px)';
        if(pixed <= -(element.scrollWidth - (width-20))) {
          let first = self.authorViews[index];
          self.authorViews.push(first);
          index++
        }
        // 下一次重绘之前更新动画帧所调用的函数(即上面所说的回调函数)。
        // 该回调函数会被传入DOMHighResTimeStamp参数，该参数与performance.now()的返回值相同，
        // 它表示requestAnimationFrame() 开始去执行回调函数的时刻。
        window.requestAnimationFrame(run);
      }
      run()
    },
    openApp() {
      if(this.isApp) {
        if(this.$Is_Android){
          window.app.toLoginOrCertificate();
        }else if(this.$Is_IOS){
          window.webkit.messageHandlers.toLoginOrCertificate.postMessage('');
        }
      }else {
        this.$store.commit('downloadChannel','ad04')
        Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true })
      }
      
    }
  }
}
</script>
<style lang="less" scoped>
  .container{
    position: relative;
    overflow: hidden;
  }
  .image-box{
    img{
      display: block;
      width: 100%;

    }
  }
  .content{
    width: 100%;
    background: #1819A5;
    .title_img{
      display: block;
      width: 91%;
      margin: auto;
    }
  }
  .pos-relative{
    position: relative;
    .pos-absolute{
      position: absolute;
      left: 0;
      top: 36%;
      width: 100%;
      .erweima{
        display: block;
        width: 100px;
        height: 100px;
        margin: auto;
      }
      .text{
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        color: rgba(255,255,255,0.7);
      }
      .button {
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translate(-50%,0);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 45px;
        margin: 50px auto 0 auto;
        background: linear-gradient(90deg, #131FDA 1%, #C80840 100%);
        box-shadow: 0px 6px 20px 0 rgba(0,0,0,0.3);
        border-radius: 49px;
        img{
          display: block;
          width: 70%;
        }
      }
    }
  }
  .swiper{
    padding: 20px 5px 0 5px;
  }
  .swiper-slide{
    transition: 300ms;
    transform: scale(0.8);
    opacity: 0.7;
  }
  .swiper-slide-active,.swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
  }
  .swiper-slide{
    width: 100px;
    font-size: 12px;
    color: white;
    transition: 300ms;
    .author-swipe-item{
      background: rgba(5,157,255,0.5);
      width: 100%;
      height: 185px;
      padding: 18px 13px 13px 13px;
      box-sizing: border-box;
      margin: auto;
      border-radius: 5px;
    }
    .author-img-bg{
      position: relative;
      width: 55px;
      height: 55px;
      margin: 0 auto 5px auto;
      background: linear-gradient(-180deg, #B60F3A 0%, rgba(182, 15, 58, 0) 100%);
      border-radius: 0px 0px 16px 0px;
    }
    .author-img{
      position: absolute;
      left: 5px;
      top: 5px;
      display: block;
      width: 55px;
      height: 55px;
      object-fit: cover;
      border-radius: 0 0 16px 0;
    }
    .nickname{
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding: 15px 0 8px 0;
      word-break: break-all;
    }
    .introduction{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      opacity: 0.8;
    }
  }
  .author-views{
    position: absolute;
    left: 0;
    top: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    .views-item{
      padding: 6px 12px 7px 12px;
      margin-right: 20px;
      border-radius: 25px;
      flex-shrink: 0;
      font-size: 12px;
      background: rgba(0,0,38,0.4);
      color: rgba(255,255,255,0.6);
      .mark{
        color: #ffb220;
      }
    }
  }
</style>