<template>
  <div class='framerdetail'>
  <!--  创作者任务详情  -->
    <div class='detail-top'>
      <div class='img' :style='{background: "url("+detailData.cover+") no-repeat center center"}'></div>
      <div class='content'>{{detailData.title}}</div>
    </div>
    <div class='detail-info'>
      <div class='text1'>奖励总额：{{ rewardType }}</div>
      <div class='text'>可参与人数：{{ detailData.number }}</div>
      <div class='text'>体裁类型：{{ type[detailData.genre] }}</div>
      <div class='text'>任务标签：{{ detailData.label }}</div>
      <div class='text2'>任务时间：{{detailData.startTime}} - {{detailData.endTime}}</div>
    </div>
    <div class='detailline'></div>
    <div style='margin-bottom: 78px;'>
      <div class='detail-title'>任务简介</div>
      <div class='abstract' v-html='detailData.brief'></div>
      <div class='detail-title'>任务要求</div>
      <div class='abstract' v-html='detailData.requirement'></div>
      <div class='detail-title'>规则说明</div>
      <div class='abstract' v-html="detailData.rule && detailData.rule.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></div>
      <div class='detail-title'>奖励公布说明</div>
      <div class='abstract' v-html="detailData.reward && detailData.reward.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></div>
      <div class='detail-title'>平台说明</div>
      <div class='abstract' v-html="detailData.statement && detailData.statement.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></div>
    </div>
    <div class='btn-wrap'>
      <van-button @click='startTask' :loading='loading' v-if='detailData.state === 1 || detailData.state === 2' block round icon-prefix='iconfont' icon="iconfont iconfabu" class="task-btn">
        {{ status[detailData.state] }}
      </van-button>
      <van-button v-else block round class="task-btn task-btn-end">
        {{ status[detailData.state] }}
      </van-button>
    </div>
    <!-- 底部弹出层 -->
    <van-popup position="bottom" v-model="selectShow">
      <ul class="select-images">
        <li v-for="item in selectIcon" :key="item.src" @click="toAppPage(item.type)">
          <img width="55" height="55" :src="item.src" alt="">
          <p class="select-title">{{item.title}}</p>
        </li>
      </ul>
      <div class="select-cancel" @click="cancelHandler()">取消</div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: 'FramerDetail',
  props: ['id'],
  computed: {
    rewardType() {
      return this.detailData.rewardType === 0 ? this.detailData.rewardAmount + '鱼仔' : this.detailData.rewardType === 1 ? this.detailData.rewardAmount + '罐头' : this.detailData.rewardType === 2 ? '现金' : '礼物'
    }
  },
  created() {
    
    window.updateToken = (token) => {
      this.isRequest = true;
      this.t = token;
      if (this.t) {
        this.getTaskDetail(this.t)
      } else {
        this.$router.push('/framertoapp')
      }
    }
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.getToken.postMessage('');
    } else {
      window.app.getToken()
    }

    setTimeout(() => {
      if(!this.isRequest) {
        this.t = this.t || this.$route.query.t
        if (this.t) {
          this.getTaskDetail(this.t)
        }else {
          this.$router.push('/framertoapp')
        }
      }
      
    }, 1000);
  },
  data() {
    return {
      loading: false, // loading状态，防止网络差的时候，防抖节流
      detailData: {},
      type: Object.freeze({
        1: '文章', 
        2: '视频', 
        3: '动态',
        4: '不限'
      }),
      androidPara: Object.freeze({
        1: '/kdnet/club/person/activity/ArticlePostActivity', 
        2: '/appalbum/activity/SelectVideoActivity', 
        3: '/appalbum/activity/SelectVideoActivity'
      }),
      status: Object.freeze({
        1: '开始任务', 
        2: '开始任务', 
        3: '已结束'
      }),
      t: '',
      isRequest: false,
      selectShow: false,
      selectIcon: Object.freeze([
        {
          src: 'https://cdn.9kd.com/kdnet/publish-monment-icon.png', 
          title: '发动态', 
          type: this.$constant.creatorGenres.MOMENT
        },
        {
          src: 'https://cdn.9kd.com/kdnet/publish-video-icon.png', 
          title: '发视频', 
          type: this.$constant.creatorGenres.VIDEO
        },
        {
          src: 'https://cdn.9kd.com/kdnet/publish-article-icon.png', 
          title: '发文章', 
          type: this.$constant.creatorGenres.ARTICLE
        }
      ])
    }
  },
  methods: {
    /* 任务详情 */
    async getTaskDetail(t) {
      let {data: res} = await this.$api.taskDetail(this.id, t)
      if (res.code !== 200) return this.$router.push('/framertoapp')
      this.detailData = res.data
    },
    /* 与APP通信做任务 */
    communicate() {
      let { UNLIMITED } = this.$constant.creatorGenres;
      if (this.detailData.genre === UNLIMITED) {
        this.selectShow = true;
        return
      }
      this.toAppPage(this.detailData.genre)
    },
    toAppPage(genreType) {
      this.cancelHandler();
      if (window.webkit && window.webkit.messageHandlers) {
        // IOS
        window.webkit.messageHandlers.publishContent.postMessage(JSON.stringify({"type": genreType, "label": this.detailData.label}));
      } else {
        window.app.goToPage(this.androidPara[genreType],JSON.stringify({"label": this.detailData.label, "type": genreType}))
      }
    },
    cancelHandler() {
      this.loading = false;
      this.selectShow = false;
    },
    /* 开始任务 */
    async startTask() {
      this.loading = true
      if (this.detailData.state === 2) {
        this.communicate()
      } else {
        let {data: res} = await this.$api.startMyTask(this.id, this.t)
        this.loading = false
        if (res.code === 200) {
          this.getTaskDetail(this.t)
          this.communicate()
        } else {
          Dialog.alert({
            message: res.msg,
            theme: 'round-button',
            confirmButtonText: '知道了'
          }).then(() => {
            // on close
          });
        }
      }
    }
  }
}
</script>

<style scoped lang='less'>
.framerdetail {
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
  .detail-top {
    display: flex;
    height: 78px;
    margin-bottom: 8px;
    .img {
      flex-shrink: 0;
      width: 118px;
      height: 78px;
      margin-right: 16px;
      border-radius: 6px;
      background-size: cover !important;
    }
    .content {
      flex-shrink: 1;
      width: 100%;
      height: 78px;
      line-height: 25px;
      color: #222;
      font-size: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .detail-info {
    box-sizing: border-box;
    padding: 0 12px;
    margin-bottom: 12px;
    width: 343px;
    background: #FAFAFA;
    .text {
      font-size: 12px;
      color: #666;
      margin-bottom: 8px;
    }
    .text1 {
      font-size: 12px;
      color: #666;
      padding: 12px 0 8px 0;
    }
    .text2 {
      font-size: 12px;
      color: #666;
      padding-bottom: 12px;
    }
  }
  .detailline {
    width: 375px;
    height: 8px;
    background: #F7F7F7;
    margin-left: -16px;
    margin-bottom: 16px;
  }
  .detail-title {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    vertical-align: bottom;
    &::before {
      content: '';
      display: inline-block;
      width: 2px;
      height: 15px;
      background: #F7321C;
      margin-right: 4px;
      margin-bottom: -2px;
    }
  }
  .abstract {
    width: 343px;
    color: #666666;
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .btn-wrap {
    width: 375px;
    height: 68px;
    background: #fff;
    position: fixed;
    bottom: 0px;
    left: 0px;
    border-top: 1px solid #F0EDED;
    .task-btn {
      width: 343px;
      margin: 12px auto 0px;
      height: 40px;
      font-size: 17px;
      font-weight: bold;
      color: #FFFFFF;
      background: #F7321C;
      .iconfont {
        font-weight: normal;
        text-align: center;
        color: #fff;
        font-size: 17px;
      }
    }
    .task-btn-end {
      background: #ddd;
    }
  }
  .select-images{
    display: flex;
    justify-content: space-around;
    padding: 36px;
    text-align: center;
    img{
      border-radius: 18px;
      background: #FFEDED;
    }
    .select-title{
      font-size: 13px;
      font-weight: bold;
    }
  }
  .select-cancel{
    padding: 19px 0;
    border-top: 1px solid #F0EDED;
    text-align: center;
    font-size: 15px;
  }
}
</style>
